<template lang="pug">
    FaIcon.app-chevron-icon(
      :icon="up ? 'chevron-up' : 'chevron-down'"
    )
</template>

<script>
  export default {
    props: {
      up: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-chevron-icon
    color: $icon-color
    font-weight: 200
</style>
